import * as React from "react";
import { IAttachment } from "../../infrastructure/api/applicants";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FilePlaceholder from "../general/FilePlaceholder";

interface IApplicantQualification {
  files: IAttachment[];
}

export default function ApplicantFiles(props: IApplicantQualification) {
  if (!props.files) {
    return <></>;
  }
  return (
    <Grid container spacing={2}>
      {props.files.map((file) => {
        return (
          <Grid item xs={3}>
            <Card>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 1,
                  alignItems: "center",
                }}
              >
                <FilePlaceholder />
              </Box>
              <CardContent>
                <Typography
                  style={{ wordWrap: "break-word" }}
                  fontSize={12}
                  pb={2}
                >
                  {file.attributes.name}
                </Typography>
                <Link
                  href={file.attributes.url}
                  color="secondary"
                  underline="hover"
                  target={"_blank"}
                >
                  <Stack direction="row" alignItems="center" gap={1}>
                    <DownloadIcon fontSize={"small"} />
                    <Typography color={"secondary"}>Download</Typography>
                  </Stack>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
