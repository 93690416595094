import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  getApplicant,
  IApplicant,
  IComment,
} from "../../infrastructure/api/applicants";
import ApplicantCommentsSection from "./ApplicantCommentsSection";
import ApplicantForm from "./ApplicantForm";
import ApplicantQualification from "./ApplicantQualification";
import ApplicantFiles from "./ApplicantFiles";
import { ApplicantHeader } from "./ApplicantHeader";

// TODO: move to separate file
const initValues: IApplicant = {
  id: "",
  address: "",
  age: 0,
  createdAt: "",
  education: "",
  email: "mail@mail.de",
  experience: "",
  lastContact: "",
  meetingAt: "",
  name: "",
  number: "",
  salary: 0,
  startAt: "",
  status: "open",
  updatedAt: "",
  qualification: "",
  comments: {
    data: [],
  },
  attachments: {
    data: [],
  },
  campaign: {
    data: {
      id: "23",
      attributes: {
        name: "",
      },
    },
  },
};

interface IBasicModal {
  open: boolean;
  applicantId: string;

  handleClose(): void;

  handleSubmit(value: IApplicant): void;

  handleCommentSubmit(
    applicantId: string,
    comment: string
  ): Promise<IComment | undefined>;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function BasicModal(props: IBasicModal) {
  const [tab, setTab] = useState(0);
  const [applicant, setApplicant] = useState(initValues);

  useEffect(() => {
    const test = async () => {
      if (props.applicantId !== "") {
        const response = await getApplicant(props.applicantId);
        setApplicant(response);
      }
    };
    test();
  }, [props.applicantId]);

  const handleComment = async (comment: string) => {
    const response = await props.handleCommentSubmit(
      props.applicantId,
      comment
    );
    if (response) {
      setApplicant({
        ...applicant,
        comments: {
          data: [...applicant.comments.data, response],
        },
      });
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={props.open}
      onClose={() => {
        setApplicant(initValues);
        props.handleClose();
      }}
    >
      <DialogTitle>
        <ApplicantHeader
          name={applicant.name}
          campaign={applicant.campaign?.data.attributes.name || ""}
        />
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Box>
          <ApplicantForm
            handleSubmit={(value) => props.handleSubmit(value)}
            applicant={applicant}
          />
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="applicant tabs"
                textColor="secondary"
                indicatorColor="secondary"
              >
                <Tab label="Vorqualifizierung" id={"0"} />
                <Tab label={`Dokumente (${applicant.attachments?.data ? applicant.attachments.data.length : "0"})`} id={"1"} />
                <Tab label="Kommentare" id={"2"} />
              </Tabs>
              <TabPanel value={tab} index={0}>
                <ApplicantQualification markdown={applicant.qualification} />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ApplicantFiles files={applicant.attachments?.data} />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <ApplicantCommentsSection
                  handleCommentSubmit={(comment) => handleComment(comment)}
                  comments={applicant.comments?.data || []}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
