import client from "./client";
import { getAuthHeader } from "./authHeader";

export const getApplicants = async (
  campaigns: string[],
  fields?: string[]
): Promise<IApplicant[]> => {
  const request = await client.get("/applicants", {
    params: {
      populate: "*",
      campaigns,
      fields,
    },
    headers: getAuthHeader(),
  });
  // @ts-ignore
  const data = request.data.data.map((item) => {
    const idStr = item.id.toString();
    return { ...item.attributes, id: idStr };
  });
  return data;
};

export const getApplicant = async (id: string): Promise<IApplicant> => {
  const request = await client.get("/applicants/" + id, {
    params: {
      populate: ["campaign", "comments", "comments.author", "attachments"],
    },
    headers: getAuthHeader(),
  });
  const idStr = request.data.data.id.toString();
  return { ...request.data.data.attributes, id: idStr };
};

export const updateApplicantStatus = async (id: string, status: any) => {
  const request = await client.put(
    "/applicants/" + id,
    { data: { status } },
    { headers: getAuthHeader() }
  );
  return request;
};

export const updateApplicant = async (data: IApplicant) => {
  // @ts-ignore
  delete data.comments;
  // @ts-ignore
  delete data.campaign;
  // @ts-ignore
  delete data.qualification;
  // @ts-ignore
  delete data.attachments;
  return await client.put(
    "/applicants/" + data.id,
    { data },
    { headers: getAuthHeader() }
  );
};

export const createComment = async (applicantId: string, comment: string) => {
  return client.post(
    "/comments",
    {
      data: { text: comment, applicant: applicantId },
    },
    { params: { populate: ["author"] }, headers: getAuthHeader() }
  );
};

export interface IApplicant {
  id: string;
  address: string;
  age: number;
  createdAt: string;
  education: string;
  email: string;
  experience: string;
  lastContact: string | null;
  meetingAt: string | null;
  name: string;
  number: string;
  salary: number;
  startAt: string;
  status: "open" | "checking" | "contact" | "negotiation" | "hired" | "preparing" | "rejected";
  updatedAt: string | null;
  qualification: string;
  attachments: { data: IAttachment[] };
  comments: {
    data: IComment[];
  };
  campaign: {
    data: {
      id: string;
      attributes: {
        name: string;
      };
    };
  };
}

export interface IAttachment {
  id: string;
  attributes: {
    name: string;
    url: string;
  };
}

export interface IComment {
  id: string;
  attributes: {
    text: string;
    createdAt: string;
    updatedAt: string;
    author: {
      data: {
        id: string;
        attributes: {
          name: string;
        };
      };
    };
  };
}
