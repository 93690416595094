import * as React from "react";
import { useEffect, useState } from "react";
import {
  DataGrid,
  deDE,
  GridCellParams,
  GridColDef,
  MuiEvent,
} from "@mui/x-data-grid";
import { CSVLink } from "react-csv";
import { Alert, Box, Button, Snackbar, Typography, styled } from "@mui/material";
import {
  createComment,
  getApplicants,
  IApplicant,
  IComment,
  updateApplicant,
} from "../infrastructure/api/applicants";
import BasicModal from "../components/board/ApplicantModal";
import useToken from "../hooks/useToken";
import { useNavigate } from "react-router-dom";

const StyledCSVLink = styled(CSVLink)({
  textDecoration: 'none',
  textTransform: 'none', // Prevent text from being capitalized
  color: "black"
});


const columns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 200 },
  { field: "campaign", headerName: "Kampagnie", width: 200 },
  { field: "address", headerName: "Wohnort", width: 200 },
  { field: "mail", headerName: "E-Mail", width: 200 },
  { field: "phone", headerName: "Telefon", width: 200 },
  {
    field: "meetingAt",
    headerName: "Termin",
    type: "dateTime",
    width: 150,
    valueFormatter: (params) => {
      if (params.value) {
        return new Date(params.value).toLocaleString("de-De", {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
      }
      return undefined;
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    // TODO: vllt umwandeln zum value getter damit man danach filtern kann
    valueFormatter: (params) => {
      const statusConverter = {
        open: "Offen",
        contact: "Termin vereinbart",
        negotiation: "In Verhandlung",
        hired: "Eingestellt",
        rejected: "Abgelehnt",
      };
      // @ts-ignore
      return statusConverter[params.value];
    },
  },
];

interface IApplicantRow {
  id: string;
  name: string;
  address: string | null;
  meetingAt: string | null;
  lastContact: string | null;
  campaign: string;
  status: string;
  phone: string | null;
  mail: string | null;
}

export default function ApplicantList() {
  const [applicants, setApplicants] = useState<IApplicantRow[]>([]);
  const [applicantId, setApplicantId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    text: "Erfolgreich geändert!",
  });
  const { removeToken } = useToken();
  const navigate = useNavigate();

  const init = async () => {
    // TODO: add error handling
    const allApplicants = await getApplicants([]);
    const converted = allApplicants.map((item) => {
      return {
        id: item.id,
        name: item.name,
        address: item.address,
        meetingAt: item.meetingAt,
        lastContact: item.lastContact,
        campaign: item.campaign.data.attributes.name,
        status: item.status,
        mail: item.email,
        phone: item.number
      };
    });
    setApplicants(converted);
  };

  const onApplicantSubmit = async (value: IApplicant) => {
    try {
      await updateApplicant(value);
      setApplicantId("");
      await init();
      setModalOpen(false);
      setSnack({
        open: true,
        severity: "success",
        text: "Erfolgreich geändert",
      });
    } catch (e) {
      // @ts-ignore
      if (e.response.status === 401 || e.response.status === 403) {
        removeToken();
        navigate("/login", { replace: true });
      }
      setSnack({
        open: true,
        severity: "error",
        text: "Etwas ist schiefgelaufen, versuchen Sie es später erneut.",
      });
    }
  };

  const onCommentSubmit = async (
    applicantId: string,
    comment: string
  ): Promise<IComment | undefined> => {
    try {
      const response = await createComment(applicantId, comment);
      setSnack({
        open: true,
        severity: "success",
        text: "Kommentar wurde erfolgreich erstellt.",
      });
      return response.data.data;
    } catch (e) {
      // @ts-ignore
      if (e.response.status === 401 || e.response.status === 403) {
        removeToken();
        navigate("/login", { replace: true });
      }
      setSnack({
        open: true,
        severity: "error",
        text: "Etwas ist schiefgelaufen, versuchen Sie es später erneut.",
      });
      return undefined;
    }
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <Box>
      <BasicModal
        open={modalOpen}
        handleClose={() => {
          setApplicantId("");
          setModalOpen(false);
        }}
        applicantId={applicantId}
        handleSubmit={(value) => onApplicantSubmit(value)}
        handleCommentSubmit={(applicant, comment) =>
          onCommentSubmit(applicant, comment)
        }
      />
      <Box style={{ display: "flex", justifyContent: "space-between" }} sx={{ pb: 2, pl: 1 }}>
        <Typography fontSize={24} fontWeight={600}>
          Bewerber Liste
        </Typography>
        <Button variant="outlined" ><StyledCSVLink style={{}} data={applicants} filename="bewerber.csv">CSV Exportieren</StyledCSVLink></Button>
      </Box>
      <Box style={{ height: 700, width: "100%" }}>
        <Box style={{ display: "flex", height: "100%" }}>
          <Box style={{ flexGrow: 1 }}>
            <DataGrid
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              rows={applicants}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[100]}
              onCellClick={(
                params: GridCellParams,
                event: MuiEvent<React.MouseEvent>
              ) => {
                event.defaultMuiPrevented = true;
                setApplicantId(params.row.id);
                setModalOpen(true);
              }}
            />
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          // @ts-ignore
          severity={snack.severity}
          onClose={() => setSnack({ ...snack, open: false })}
        >
          {snack.text}
        </Alert>
      </Snackbar>
    </Box>
  );
}
