import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import EventIcon from "@mui/icons-material/Event";
import BasicModal from "../components/board/ApplicantModal";
import {
  createComment,
  getApplicants,
  IApplicant,
  IComment,
  updateApplicant,
  updateApplicantStatus,
} from "../infrastructure/api/applicants";
import { formatDistance } from "date-fns";
import { de } from "date-fns/locale";
import useToken from "../hooks/useToken";
import { useNavigate } from "react-router-dom";
import LetterAvatar from "../components/general/LetterAvatar";

interface IColumns {
  [key: string]: {
    name: string;
    items: IApplicant[];
  };
}

const columnsFromBackend: IColumns = {
  open: {
    name: "Beworben",
    items: [],
  },
  checking: {
    name: "In Prüfung",
    items: [],
  },
  contact: {
    name: "Termin vereinbart",
    items: [],
  },
  negotiation: {
    name: "In Verhandlung",
    items: [],
  },
  hired: {
    name: "Eingestellt",
    items: [],
  },
  preparing: {
    name: "Absage vorbereiten",
    items: [],
  },
  rejected: {
    name: "Abgelehnt",
    items: [],
  },
};

// @ts-ignore
const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    updateApplicantStatus(result.draggableId, destination.droppableId);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

function Board() {
  const [columns, setColumns] = useState(columnsFromBackend);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedApplicantId, setApplicant] = useState("");
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    text: "Erfolgreich geändert!",
  });
  const { removeToken } = useToken();
  const navigate = useNavigate();


  const init = async () => {
    try {
      const data = await getApplicants(
        [],
        ["name", "lastContact", "meetingAt", "status"]
      );
      const openItems: IApplicant[] = [];
      const checkingItems: IApplicant[] = [];
      const contactItems: IApplicant[] = [];
      const negotiationItems: IApplicant[] = [];
      const hiredItems: IApplicant[] = [];
      const preparingItems: IApplicant[] = [];
      const rejectedItems: IApplicant[] = [];
      data.forEach((item) => {
        if (item.status === "open") {
          openItems.push(item);
        } else if (item.status === "checking") {
          checkingItems.push(item);
        } else if (item.status === "contact") {
          contactItems.push(item);
        } else if (item.status === "negotiation") {
          negotiationItems.push(item);
        } else if (item.status === "hired") {
          hiredItems.push(item);
        } else if (item.status === "preparing") {
          preparingItems.push(item);
        } else if (item.status === "rejected") {
          rejectedItems.push(item);
        }
      });
      setColumns({
        ...columns,
        open: { name: columns.open.name, items: openItems },
        checking: { name: columns.checking.name, items: checkingItems },
        contact: { name: columns.contact.name, items: contactItems },
        negotiation: {
          name: columns.negotiation.name,
          items: negotiationItems,
        },
        hired: {
          name: columns.hired.name,
          items: hiredItems,
        },
        preparing: { name: columns.preparing.name, items: preparingItems },
        rejected: {
          name: columns.rejected.name,
          items: rejectedItems,
        },
      });
    } catch (e) {
      // @ts-ignore
      if (e.response.status === 401 || e.response.status === 403) {
        removeToken();
        navigate("/login", { replace: true });
      }
      // @ts-ignore
      if (e.response.status === 400 || e.response.status === 500) {
        setSnack({
          open: true,
          severity: "error",
          text: "Etwas ist schiefgelaufen, versuchen Sie es später erneut.",
        });
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  const onApplicantSubmit = async (value: IApplicant) => {
    try {
      await updateApplicant(value);
      setApplicant("");
      await init();
      setModalOpen(false);
      setSnack({
        open: true,
        severity: "success",
        text: "Erfolgreich geändert",
      });
    } catch (e) {
      // @ts-ignore
      if (e.response.status === 401 || e.response.status === 403) {
        removeToken();
        navigate("/login", { replace: true });
      }
      setSnack({
        open: true,
        severity: "error",
        text: "Etwas ist schiefgelaufen, versuchen Sie es später erneut.",
      });
    }
  };

  const onCommentSubmit = async (
    applicantId: string,
    comment: string
  ): Promise<IComment | undefined> => {
    try {
      const response = await createComment(applicantId, comment);
      setSnack({
        open: true,
        severity: "success",
        text: "Kommentar wurde erfolgreich erstellt.",
      });
      return response.data.data;
    } catch (e) {
      // @ts-ignore
      if (e.response.status === 401 || e.response.status === 403) {
        removeToken();
        navigate("/login", { replace: true });
      }
      setSnack({
        open: true,
        severity: "error",
        text: "Etwas ist schiefgelaufen, versuchen Sie es später erneut.",
      });
      return undefined;
    }
  };

  return (
    <div>
      <BasicModal
        open={modalOpen}
        handleClose={() => {
          setApplicant("");
          setModalOpen(false);
        }}
        applicantId={selectedApplicantId}
        handleSubmit={(value) => onApplicantSubmit(value)}
        handleCommentSubmit={(applicant, comment) =>
          onCommentSubmit(applicant, comment)
        }
      />
      <Typography fontSize={24} fontWeight={600} sx={{ pb: 2, pl: 1 }}>
        Bewerber Board
      </Typography>
      <div
        style={{
          display: "inline-flex",
        }}
      >
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#F1F3F5",
                  marginRight: 16,
                  borderRadius: 8,
                  paddingTop: 8,
                  width: 300,
                }}
                key={columnId}
              >
                <Typography
                  sx={{ textAlign: "left", pl: 2, fontWeight: "600" }}
                >
                  {column.name}
                </Typography>
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      // @ts-ignore
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "#D8DBE2"
                              : "#F1F3F5",
                            padding: 4,
                            // width: 284,
                            minHeight: 700,
                            borderRadius: 8,
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        margin: "0 0 8px 0",
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <Card
                                        sx={{ maxWidth: 345 }}
                                        onClick={() => {
                                          setApplicant(item.id);
                                          setModalOpen(true);
                                        }}
                                      >
                                        <CardHeader
                                          avatar={
                                            <LetterAvatar
                                              name={item.name}
                                              mr={0}
                                              width={40}
                                              height={40}
                                              fontSize={16}
                                            />
                                          }
                                          title={item.name}
                                          subheader={
                                            item.campaign.data.attributes.name
                                          }
                                        />
                                        <CardContent sx={{ paddingTop: 0 }}>
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                          >
                                            {item.lastContact ? (
                                              <>
                                                <ScheduleIcon
                                                  fontSize={"small"}
                                                />
                                                <Typography
                                                  fontSize={14}
                                                  color={"gray"}
                                                >
                                                  {formatDistance(
                                                    new Date(item.lastContact),
                                                    new Date(),
                                                    {
                                                      addSuffix: true,
                                                      locale: de,
                                                    }
                                                  )}
                                                </Typography>
                                              </>
                                            ) : null}
                                            <>
                                              <EventIcon fontSize={"small"} />
                                              <Typography
                                                fontSize={14}
                                                color={"gray"}
                                              >
                                                {item.meetingAt
                                                  ? new Date(
                                                      item.meetingAt
                                                    ).toLocaleString("de-De", {
                                                      month: "short",
                                                      day: "numeric",
                                                    })
                                                  : "Kein Termin"}
                                              </Typography>
                                            </>
                                          </Stack>
                                        </CardContent>
                                      </Card>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          // @ts-ignore
          severity={snack.severity}
          onClose={() => setSnack({ ...snack, open: false })}
        >
          {snack.text}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Board;
